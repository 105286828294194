import React from "react";
import { useState } from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
// components
import { DefaultLayout } from "../components/layout/defaultLayout";
import { PageHeading } from "../components/heading/pageHeading";
import { BreadcrumbBar } from "../components/bar/breadcrumbBar";
import { BackBtn } from "../components/button/backBtn";
// utils
import { Color } from "../utils/color";
import { NewsLists } from "../utils/newsLists";
// breadcrumb
const breadcrumbs = [
  { txt: "トップ", url: "/" },
  { txt: "お知らせ", url: "/news" },
];

// 抽出条件を色で判別
const NewsCompanyLists = NewsLists.filter((News) => {
  return News.tagFlg === "red";
});
const NewsSaleLists = NewsLists.filter((News) => {
  return News.tagFlg === "yellow";
});
const NewsStoreLists = NewsLists.filter((News) => {
  return News.tagFlg === "blue";
});
const NewsProductLists = NewsLists.filter((News) => {
  return News.tagFlg === "green";
});

const NewsPage = () => {
  const [isSelect, setIsSelect] = useState("all");
  const onClickSelect = (e) => {
    const target = e.currentTarget.getAttribute("data-select-btn");
    setIsSelect(target);
  };
  return (
    <DefaultLayout pageTitle="お知らせ">
      <PageHeading enTxt="NEWS" jpTxt="お知らせ" />
      <BreadcrumbBar breadcrumbs={breadcrumbs} />
      <SSection>
        <SContainer>
          <SSelect data-is-select={isSelect}>
            <button
              onClick={onClickSelect}
              className="all"
              data-select-btn="all"
            >
              ALL
            </button>
            <button
              onClick={onClickSelect}
              className="company"
              data-select-btn="company"
            >
              会社情報
            </button>
            <button
              onClick={onClickSelect}
              className="sale"
              data-select-btn="sale"
            >
              セール
            </button>
            <button
              onClick={onClickSelect}
              className="store"
              data-select-btn="store"
            >
              ストア
            </button>
            <button
              onClick={onClickSelect}
              className="product"
              data-select-btn="product"
            >
              商品紹介
            </button>
          </SSelect>
          <SWrapper className="all" data-is-select={isSelect}>
            {NewsLists.map(({ id, date, url, tagTxt, tagFlg, title }) => {
              return (
                <Link to={url} key={id}>
                  <span>{date}</span>
                  <span data-tag-type={tagFlg}>#{tagTxt}</span>
                  <span>{title}</span>
                </Link>
              );
            })}
          </SWrapper>
          <SWrapper className="company" data-is-select={isSelect}>
            {NewsCompanyLists.map(
              ({ id, date, url, tagTxt, tagFlg, title }) => {
                return (
                  <Link to={url} key={id}>
                    <span>{date}</span>
                    <span data-tag-type={tagFlg}>#{tagTxt}</span>
                    <span>{title}</span>
                  </Link>
                );
              }
            )}
          </SWrapper>
          <SWrapper className="sale" data-is-select={isSelect}>
            {NewsSaleLists.map(({ id, date, url, tagTxt, tagFlg, title }) => {
              return (
                <Link to={url} key={id}>
                  <span>{date}</span>
                  <span data-tag-type={tagFlg}>#{tagTxt}</span>
                  <span>{title}</span>
                </Link>
              );
            })}
          </SWrapper>
          <SWrapper className="store" data-is-select={isSelect}>
            {NewsStoreLists.map(({ id, date, url, tagTxt, tagFlg, title }) => {
              return (
                <Link to={url} key={id}>
                  <span>{date}</span>
                  <span data-tag-type={tagFlg}>#{tagTxt}</span>
                  <span>{title}</span>
                </Link>
              );
            })}
          </SWrapper>
          <SWrapper className="product" data-is-select={isSelect}>
            {NewsProductLists.map(
              ({ id, date, url, tagTxt, tagFlg, title }) => {
                return (
                  <Link to={url} key={id}>
                    <span>{date}</span>
                    <span data-tag-type={tagFlg}>#{tagTxt}</span>
                    <span>{title}</span>
                  </Link>
                );
              }
            )}
          </SWrapper>
          <SFooting>
            <BackBtn />
          </SFooting>
        </SContainer>
      </SSection>
    </DefaultLayout>
  );
};

export default NewsPage;

const SSection = styled.section`
  padding: 4rem 0;
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    padding: 2rem 0;
  }
`;

const SContainer = styled.div`
  width: 96%;
  max-width: 1366px;
  margin: auto;
`;

const SSelect = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    width: 160px;
    height: 40px;
    background-color: ${Color.white};
    border: 1px solid ${Color.gray400};
    border-left: none;
    cursor: pointer;
    transition: all 0.2s;
    &:first-of-type {
      border-left: 1px solid ${Color.gray400};
      border-radius: 8px 0 0 8px;
    }
    &:last-of-type {
      border-radius: 0 8px 8px 0;
    }
  }
  &[data-is-select="all"] {
    .all {
      background-color: ${Color.gray400};
      color: ${Color.white};
    }
  }
  &[data-is-select="company"] {
    .company {
      background-color: ${Color.gray400};
      color: ${Color.white};
    }
  }
  &[data-is-select="sale"] {
    .sale {
      background-color: ${Color.gray400};
      color: ${Color.white};
    }
  }
  &[data-is-select="store"] {
    .store {
      background-color: ${Color.gray400};
      color: ${Color.white};
    }
  }
  &[data-is-select="product"] {
    .product {
      background-color: ${Color.gray400};
      color: ${Color.white};
    }
  }
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    button {
      width: calc(100% / 5);
    }
  }
`;

const SWrapper = styled.div`
  display: none;
  margin-top: 2rem;
  flex-direction: column;
  &[data-is-select="all"] {
    &.all {
      display: flex;
    }
  }
  &[data-is-select="company"] {
    &.company {
      display: flex;
    }
  }
  &[data-is-select="sale"] {
    &.sale {
      display: flex;
    }
  }
  &[data-is-select="store"] {
    &.store {
      display: flex;
    }
  }
  &[data-is-select="product"] {
    &.product {
      display: flex;
    }
  }
  a {
    padding: 2rem 0;
    display: flex;
    align-items: center;
    border-bottom: 1px dashed ${Color.gray100};
    &:last-of-type {
      border-bottom: none;
    }
    span {
      color: ${Color.gray300};
      font-size: 0.9rem;
      line-height: 1;
      &:nth-of-type(1) {
        margin-right: 1rem;
      }
      &:nth-of-type(2) {
        font-size: 0.6rem;
        padding: 4px;
        border-radius: 4px;
        font-weight: 500;
        margin-right: 2rem;
        min-width: 64px;
        text-align: center;
        &[data-tag-type="blue"] {
          background-color: ${Color.blue};
          color: ${Color.white};
        }
        &[data-tag-type="green"] {
          background-color: ${Color.green};
          color: ${Color.gray400};
        }
        &[data-tag-type="red"] {
          background-color: ${Color.red};
          color: ${Color.white};
        }
        &[data-tag-type="yellow"] {
          background-color: ${Color.yellow};
        }
      }
      &:nth-of-type(3) {
        transition: all 0.2s;
      }
    }
    @media screen and (min-width: 1024px) {
      &:hover {
        span {
          &:nth-of-type(3) {
            color: ${Color.blue};
          }
        }
      }
    }
    @media screen and (max-width: 1023px) {
      &:active {
        span {
          &:nth-of-type(3) {
            color: ${Color.blue};
          }
        }
      }
    }
  }
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    a {
      flex-wrap: wrap;
      padding: 1rem 0;
      span {
        &:nth-of-type(3) {
          width: 100%;
          margin-top: 0.5rem;
        }
      }
    }
  }
`;

const SFooting = styled.div`
  margin-top: 2rem;
  text-align: center;
`;
