import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
// utils
import { Color } from "../../utils/color";

export const BackBtn = () => {
  return (
    <SBtn>
      <Link to="/">トップページに戻る</Link>
    </SBtn>
  );
};
const SBtn = styled.span`
  a {
    display: inline-flex;
    align-items: center;
    font-size: 0.8rem;
    padding: 1rem 2rem;
    border-radius: 8px;
    border: 1px solid ${Color.gray400};
    color: ${Color.gray400};
    transition: all 0.2s;
    justify-content: center;
    @media screen and (min-width: 1024px) {
      &:hover {
        color: ${Color.white};
        background-color: ${Color.gray400};
      }
    }
    @media screen and (max-width: 1023px) {
      &:active {
        color: ${Color.white};
        background-color: ${Color.gray400};
      }
    }
  }
  /* ---------- */
  /* tb */
  @media screen and (max-width: 1023px) {
    a {
      width: 100%;
    }
  }
`;
