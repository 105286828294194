export const NewsLists = [
  {
    id: 1,
    date: "2022/01/01",
    tagTxt: "会社情報",
    tagFlg: "red",
    url: "/news/post",
    title: "オンラインショップ開店のお知らせ",
  },
  {
    id: 2,
    date: "2022/01/02",
    tagTxt: "セール",
    tagFlg: "yellow",
    url: "/news/post",
    title: "オンラインショップ開店セールのお知らせ",
  },
  {
    id: 3,
    date: "2022/01/03",
    tagTxt: "ストア",
    tagFlg: "blue",
    url: "/news/post",
    title: "XXXX製品がオンラインストアに追加",
  },
  {
    id: 4,
    date: "2022/01/04",
    tagTxt: "商品紹介",
    tagFlg: "green",
    url: "/news/post",
    title: "XXXXXXのお取り扱いが始まります",
  },
  {
    id: 5,
    date: "2022/01/05",
    tagTxt: "会社情報",
    tagFlg: "red",
    url: "/news/post",
    title: "プライバシーポリシー変更のお知らせ",
  },
  {
    id: 6,
    date: "2022/01/06",
    tagTxt: "セール",
    tagFlg: "yellow",
    url: "/news/post",
    title: "XXXX関連製品のセールのお知らせ",
  },
  {
    id: 7,
    date: "2022/01/07",
    tagTxt: "ストア",
    tagFlg: "blue",
    url: "/news/post",
    title: "XXXXXXのお取り扱いが始まります",
  },
];
